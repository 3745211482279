import React, { useEffect } from "react";
import "./../../../../assets/css/Dashboard/ServicesKanban.scss";
import { ImWarning } from "react-icons/im";
import {
  setCurrentPatientContact,
  setCurrentContactServiceId,
  setEligibilityStageScrollPosition,
} from "../../../../dispatchers/pharmacyServiceDispatchers";

function PatientTelephone({
  id,
  index,
  setShowPatientContactModal,
  setEligibilityScroll,
  setContactServiceID,
}) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setCurrentPatientContact(false);
        setCurrentContactServiceId("");
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleTelephoneClick = (e) => {
    e.stopPropagation();
    const stageEligible = document.querySelector(".infinite-scroll-Eligible");
    setEligibilityScroll(stageEligible.scrollTop);
    setShowPatientContactModal(true);
    setContactServiceID(id);
  };

  return (
    <div
      key={index}
      className="service_contact_no"
      onClick={(e) => {
        handleTelephoneClick(e);
      }}
    >
      <ImWarning size={16} color={"red"} />
      <p>Patient doesn't have mobile number</p>
    </div>
  );
}

export default React.memo(PatientTelephone);
