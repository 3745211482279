import React, { useEffect, useState, useRef, useCallback } from "react";
import "./../../../../../assets/css/Dashboard/ServicesKanban.scss";
import { useNavigate } from "react-router-dom";
import { ReactComponent as NHSIcon } from "../../ServicesIcons/nhs.svg";
import useTitanApp from "../../../../../hooks/useTitanApp";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Skeleton } from "@mui/material";

import {
  getDueName,
  getTotalServicesDueCount,
} from "../../../../../data/getStartDateForStages";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import ServiceItemList from "./ServiceItemList";
import InfiniteScroll from "react-infinite-scroll-component";
import debounce from "lodash.debounce";
function ServicesKanbanAccordian({
  service,
  currentIndex,
  stage,
  smsQueue,
  reSmsQueue,
  interventionQueue,
  declineQueue,
  rescheduleQueue,
  pdsQueue,
  orbitQueue,
  setCurrentSmsQueue,
  setCurrentReSmsQueue,
  setCurrentInterventionQueue,
  setCurrentDeclineQueue,
  setCurrentRescheduleQueue,
  setCurrentPdsQueue,
  setCurrentOrbitQueue,
  checksCount,
  setChecksCount,
  servicesUpdatedData,
  setShowPatientContactModal,
  setEligibilityScroll,
  setContactServiceID,
}) {
  const { pharmacyServiceState, appState } = useTitanApp();
  const { darkMode } = appState || {};
  const { isPharmacyServiceLoading, pharmacyServices } = pharmacyServiceState;
  const [privateServices, setPrivateServices] = useState(service);

  const [visibleServices, setVisibleServices] = useState({});
  const [hasMore, setHasMore] = useState(true);

  const groupedClinicalServices = privateServices?.clinicalServices.reduce(
    (acc, clinicalService) => {
      const { patientId, clinicalServiceDate } = clinicalService;

      acc[patientId] = acc[patientId] || {};
      const dateKey = new Date(clinicalServiceDate).toISOString().split("T")[0];

      acc[patientId][dateKey] = acc[patientId][dateKey] || [];
      acc[patientId][dateKey].push(clinicalService);

      return acc;
    },
    {}
  );
  // Track progress
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [currentDateIndices, setCurrentDateIndices] = useState({});

  const fetchMoreData = useCallback(() => {
    const groupKeys = Object.keys(groupedClinicalServices);
    if (currentGroupIndex >= groupKeys.length) {
      setHasMore(false);
      return;
    }

    const updatedServices = { ...visibleServices };
    let newGroupIndex = currentGroupIndex;
    let loadCount = 0;

    while (newGroupIndex < groupKeys.length && loadCount < 20) {
      const groupKey = groupKeys[newGroupIndex];
      const dates = Object.keys(groupedClinicalServices[groupKey]);
      const currentDateIndex = currentDateIndices[groupKey] || 0;

      for (let i = currentDateIndex; i < dates.length; i++) {
        const date = dates[i];
        if (!updatedServices[groupKey]) {
          updatedServices[groupKey] = {};
        }
        if (!updatedServices[groupKey][date]) {
          updatedServices[groupKey][date] =
            groupedClinicalServices[groupKey][date];
          loadCount += groupedClinicalServices[groupKey][date].length;
          if (loadCount >= 20) {
            setCurrentDateIndices((prev) => ({
              ...prev,
              [groupKey]: i + 1,
            }));
            break;
          }
        }
      }

      if (loadCount < 20) {
        newGroupIndex++;
      }
    }

    setVisibleServices(updatedServices);
    setCurrentGroupIndex(newGroupIndex);
    if (newGroupIndex >= groupKeys.length) {
      setHasMore(false);
    }
  }, [
    groupedClinicalServices,
    currentGroupIndex,
    currentDateIndices,
    visibleServices,
  ]);
  const debouncedFetchData = useCallback(debounce(fetchMoreData, 500), [
    fetchMoreData,
  ]); // 500ms debounce

  useEffect(() => {
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  // useEffect(() => {
  //   function toggleScrollbar() {
  //     const columns = document.querySelectorAll(".stage");
  //     columns.forEach((column) => {
  //       if (column.scrollHeight > column.clientHeight) {
  //         column.classList.add("with-scrollbar");
  //       } else {
  //         column.classList.remove("with-scrollbar");
  //       }
  //     });
  //   }
  //   toggleScrollbar();
  //   window.addEventListener("resize", toggleScrollbar);
  // }, []);

  return (
    <Accordion key={currentIndex} className="dueDate_accordion" defaultExpanded>
      <AccordionSummary
        key={currentIndex}
        expandIcon={
          <ExpandMoreIcon fontSize="14" className="expandIcon_dueDate" />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div>
          <p className="service_title service_duedate_title">
            {isPharmacyServiceLoading ? (
              <Skeleton
                sx={{
                  bgcolor: darkMode ? "grey.900" : "",
                }}
                height={15}
                animation="wave"
              />
            ) : (
              `${getDueName(service?.dueType)} ${getTotalServicesDueCount(
                service,
                stage?.stageName,
                service?.dueType
              )}`
            )}
          </p>
        </div>
      </AccordionSummary>
      <AccordionDetails key={currentIndex} className="data-box-container">
        <InfiniteScroll
          className={`infinite-scroll-${stage?.stageName}`}
          dataLength={Object.keys(visibleServices).length} // Current length of loaded services
          next={fetchMoreData}
          hasMore={hasMore}
          scrollThreshold={0.8} // Optional: Fine-tune when fetching is triggered
          style={{ overflow: "auto" }} // Ensure scroll is handled properly
        >
          {Object.values(visibleServices).map((patientGroups, groupIndex) =>
            Object.values(patientGroups).map((group, dateIndex) =>
              group.length > 1 ? (
                <div className="stacked-cards-container">
                  {group.map((service, index) => {
                    return (
                      <>
                        <div className="check_service" key={index}>
                          <ServiceItemList
                            service={service}
                            stage={stage}
                            index={index}
                            group={group}
                            smsQueue={smsQueue}
                            reSmsQueue={reSmsQueue}
                            interventionQueue={interventionQueue}
                            declineQueue={declineQueue}
                            rescheduleQueue={rescheduleQueue}
                            pdsQueue={pdsQueue}
                            orbitQueue={orbitQueue}
                            servicesUpdatedData={servicesUpdatedData}
                            setCurrentSmsQueue={setCurrentSmsQueue}
                            setCurrentReSmsQueue={setCurrentReSmsQueue}
                            setCurrentInterventionQueue={
                              setCurrentInterventionQueue
                            }
                            setCurrentDeclineQueue={setCurrentDeclineQueue}
                            setCurrentRescheduleQueue={
                              setCurrentRescheduleQueue
                            }
                            setCurrentPdsQueue={setCurrentPdsQueue}
                            setCurrentOrbitQueue={setCurrentOrbitQueue}
                            checksCount={checksCount}
                            setChecksCount={setChecksCount}
                            setShowPatientContactModal={
                              setShowPatientContactModal
                            }
                            setEligibilityScroll={setEligibilityScroll}
                            setContactServiceID={setContactServiceID}
                          />

                          <div
                            className="bottom-stacked"
                            // style={{
                            //   width:
                            //     pharmacyServices?.length === 1
                            //       ? "100%"
                            //       : pharmacyServices?.length === 2
                            //       ? "100%"
                            //       : pharmacyServices?.length > 2
                            //       ? "430px"
                            //       : "",
                            // }}
                          ></div>
                          <div
                            className="bottom-stacked"
                            // style={{
                            //   width:
                            //     pharmacyServices?.length === 1
                            //       ? "100%"
                            //       : pharmacyServices?.length === 2
                            //       ? "100%"
                            //       : pharmacyServices?.length > 2
                            //       ? "430px"
                            //       : "",
                            // }}
                          ></div>
                        </div>
                      </>
                    );
                  })}
                </div>
              ) : (
                group.map((service, index) => {
                  return (
                    <div className="check_service" key={index}>
                      <ServiceItemList
                        service={service}
                        stage={stage}
                        index={index}
                        group={group}
                        smsQueue={smsQueue}
                        reSmsQueue={reSmsQueue}
                        interventionQueue={interventionQueue}
                        declineQueue={declineQueue}
                        rescheduleQueue={rescheduleQueue}
                        pdsQueue={pdsQueue}
                        orbitQueue={orbitQueue}
                        servicesUpdatedData={servicesUpdatedData}
                        setCurrentSmsQueue={setCurrentSmsQueue}
                        setCurrentReSmsQueue={setCurrentReSmsQueue}
                        setCurrentInterventionQueue={
                          setCurrentInterventionQueue
                        }
                        setCurrentDeclineQueue={setCurrentDeclineQueue}
                        setCurrentRescheduleQueue={setCurrentRescheduleQueue}
                        setCurrentPdsQueue={setCurrentPdsQueue}
                        setCurrentOrbitQueue={setCurrentOrbitQueue}
                        checksCount={checksCount}
                        setChecksCount={setChecksCount}
                        setShowPatientContactModal={setShowPatientContactModal}
                        setEligibilityScroll={setEligibilityScroll}
                        setContactServiceID={setContactServiceID}
                      />
                    </div>
                  );
                })
              )
            )
          )}
        </InfiniteScroll>
      </AccordionDetails>
    </Accordion>
  );
}
export default React.memo(ServicesKanbanAccordian);
