import React, { useState, useMemo } from "react";
import { TbCalendarTime } from "react-icons/tb";
import DatePicker from "react-datepicker";
import useTitanApp from "../../../../../hooks/useTitanApp";
import moment from "moment";
import {
  setCurrentPharmacyServices,
  setEligibilityStageScrollPosition,
  setInterventionStageScrollPosition,
  setScrollPosition,
  updateCalendarEventByClinicalServiceId,
} from "../../../../../dispatchers/pharmacyServiceDispatchers";
import { showError } from "../../../../../components/ToastedMessage/ToastedMessage";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

const ServiceKanbanDate = ({
  parsedDate,
  index,
  clinicalServiceId,
  stageType,
}) => {
  const [newestDateTime, setNewestDateTime] = useState("");

  const { appState, pharmacyServiceState } = useTitanApp();

  const { darkMode } = appState || {};
  const { pharmacyServices } = pharmacyServiceState;

  const calendarIconColor = useMemo(
    () => (darkMode ? "#fff" : "#000"),
    [darkMode]
  );

  const showToast = (message) => {
    toast.success(`${message}`, {
      className: "services-toast",
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
      theme: darkMode ? "dark" : "light",
    });
  };

  const handleChange = (newDate, index) => {
    if (newestDateTime !== "") {
      const firstDateTime = moment(newDate).format("HH:mm:ss");
      const updatedSecondDate = moment(newestDateTime)
        .set({
          hour: moment(firstDateTime, "HH:mm:ss").hour(),
          minute: moment(firstDateTime, "HH:mm:ss").minute(),
          second: moment(firstDateTime, "HH:mm:ss").second(),
        })
        .toDate();
      setNewestDateTime(updatedSecondDate);
    } else {
      setNewestDateTime(newDate);
    }
  };

  const handleCalendarClose = () => {
    if (newestDateTime !== "") {
      const parsedDate = moment(
        newestDateTime,
        "ddd MMM DD YYYY HH:mm:ss [GMT] ZZ (z)"
      );
      const formattedDateTime = parsedDate.format("YYYY-MM-DDTHH:mm:ss") + "Z";
      const formattedDateTimeToReplace = parsedDate.format(
        "YYYY-MM-DDTHH:mm:ss.SSSZ"
      );
      let stageNo;
      switch (stageType) {
        case 1:
          stageNo = 0;
          break;
        case 2:
          stageNo = 1;
          break;
        case 3:
          stageNo = 2;
          break;
        case 4:
          stageNo = 3;
          break;
      }

      if (formattedDateTime !== "") {
        updateCalendarEventByClinicalServiceId({
          formattedDateTime,
          clinicalServiceId,
          stageNo,
        }).then((data) => {
          try {
            const newData = pharmacyServices?.map((stage) => ({
              ...stage,
              sortedServices: stage?.sortedServices?.map((service) => ({
                ...service,
                clinicalServices: service?.clinicalServices.map(
                  (clinicalService) => ({
                    ...clinicalService,
                    stages: clinicalService?.stages.map((stage) => ({
                      ...stage,
                    })),
                  })
                ),
              })),
            }));

            newData?.forEach((stage) => {
              stage?.sortedServices?.forEach((serviceGroup) => {
                serviceGroup?.clinicalServices?.forEach((clinicalService) => {
                  if (clinicalService._id === clinicalServiceId) {
                    let stageFound = false;
                    clinicalService.stages = clinicalService?.stages?.map(
                      (stage) => {
                        if (
                          !stageFound &&
                          stage.clinicalServiceStageStatus === 0
                        ) {
                          stageFound = true;
                          return {
                            ...stage,
                            startDate: formattedDateTimeToReplace,
                          };
                        }
                        return stage;
                      }
                    );
                  }
                });
              });
            });
            showToast("Record has been rescheduled successfully!");
            setCurrentPharmacyServices(newData);
            const stagesWrapper = document.querySelector(".stages-wrapper");
            if (stagesWrapper) {
              setScrollPosition(stagesWrapper.scrollLeft);
            }
            const stageEligible = document.getElementById("stage_Eligible");
            if (stageEligible) {
              setEligibilityStageScrollPosition(stageEligible.scrollTop);
            }
            const stageIntervention =
              document.getElementById("stage_Intervention");
            if (stageIntervention) {
              setInterventionStageScrollPosition(stageIntervention.scrollTop);
            }
          } catch (error) {}
        });
      } else {
        showError("Something went wrong!");
      }
    }
  };

  return (
    <div className="services_iconboxService">
      <TbCalendarTime size={15} color={calendarIconColor} />
      <DatePicker
        className="widgetDatePicker"
        onChange={(newDate) => handleChange(newDate, index)}
        showTimeSelect
        selected={parsedDate}
        timeIntervals={15}
        dateFormat="dd.MM HH:mm"
        onCalendarClose={() => handleCalendarClose}
      />
    </div>
  );
};

export default ServiceKanbanDate;
