import React from "react";
import moment from "moment";

const PatientConsent = ({ group, service, stageType }) => {
  const hasConsent = group.some((item) => item?.isConsent);
  const isConsent = service?.isConsent;
  const consentDate = moment(service?.clinicalServiceDate).format("DD/MM");
  const shouldShowConsentText =
    (group?.length > 1 && hasConsent && stageType === 1) ||
    (group?.length <= 1 && isConsent && stageType === 1);

  return (
    <>
      {shouldShowConsentText && (
        <div className="patient_consented_text">
          <p>{consentDate} Patient Consented</p>
        </div>
      )}
    </>
  );
};

export default PatientConsent;
